import React from "react";
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/material";
import SideNav from "../../../components/commonComponents/Sidebar/SideNav";
import { StyledMainBox, StickyTopRowStackSpaceBetween } from "../../../components/commonComponents/StyledComponents/styled";
import TableauDash from "../../../components/commonComponents/TableauDashboard/TableauDash";
import { checkWorkSpaceFromCookies } from "../../../api_calls/userManagement";
import Grid from "@mui/material/Unstable_Grid2";
import DateRangeFilter from "../../../components/commonComponents/Filters/DateRangeFilter";
import { filterRangeValue } from "../../../api_calls/TableauVisuals";
import { clearfilters, fetchCampaign, loadCampaigns } from "../../../api_calls/campaignRequests";
import ClipNameFilter from "../../../components/commonComponents/Filters/ClipNameFilter";
import CampaignNameFilter from "../../../components/commonComponents/Filters/CampaignNameFilter";
import ShowHideSwitch from "../../../components/commonComponents/switches/ShowHideSwitch";
import ActionButton, { TextButton } from "../../../components/commonComponents/Buttons/ActionButton";
import { useNavigate, useParams } from "react-router-dom";
import { loadClips, resetClipsData } from "../../../api_calls/clipRequests";

import {
    dbFormatDate,
    formatToStandardDate,
    standardFormatToday,
    getFirstDayTwoMonthsAgo,
} from "../../../api_calls/utils";



const ClipOverview = () => {
    // Variables
    const userStateDate = useSelector((state) => state.userSelections);
    const [workSpaceId, setWorkSpaceId] = React.useState("");

    const firstDayTwoMonthsAgo = formatToStandardDate(getFirstDayTwoMonthsAgo());
    // make it the actual first day of the month instead of the last day of the month before
    firstDayTwoMonthsAgo.setDate(firstDayTwoMonthsAgo.getDate() + 0);


    const today = standardFormatToday();
    const yesterday = formatToStandardDate(today);
    yesterday.setDate(today.getDate() - 1);

    const startDate = firstDayTwoMonthsAgo;
    const navigate = useNavigate();
    

    React.useEffect(() => {
        const checkWorkSpaces = checkWorkSpaceFromCookies();
        if (
            checkWorkSpaces.success === true &&
            typeof checkWorkSpaces.current_workspace !== "undefined" &&
            typeof checkWorkSpaces.current_workspace.id !== "undefined"
        ) {
            setWorkSpaceId(checkWorkSpaces.current_workspace.id);
        };

        const fetchData = async () => {

            if(typeof userStateDate.campaign_list === "undefined" ){
                await loadCampaigns();
            }
            await loadClips();
        };
        fetchData();
        clearfilters();

    }, []);

    // Elements
    return  (
        <Box sx={{ display: "flex" }}>
            <SideNav />
            { typeof userStateDate.campaign_list !== "undefined" && typeof userStateDate.clips_list !== "undefined" ?
            <StyledMainBox component="main">
                <StickyTopRowStackSpaceBetween direction={"row"} spacing={2}>
                    {/* ------- Back to campaigns button ----------- */}
                <Box sx={{ alignContent: "center" }}>
                        <TextButton
                            onClick={(e) => {
                                // e.preventDefault();
                                navigate("/check-campaigns/list")
                            }}
                            label={"Back to Campaigns"}
                        />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                            <Box sx={{ alignContent: "center" }}>
                                <ShowHideSwitch usedFor={"clip-overview"} fieldName="Hide/Show" vizId={"campaignMonitoringViz"} />
                            </Box>
                        {/* ------- Clip Name Filter ----------- */}
                            {typeof userStateDate.clip_names_list !== "undefined"
                                ? <Box sx={{ width: 250, alignContent: "center" }}>
                                    <ClipNameFilter usedFor={"clip-overview"} fieldName="name_clip" vizId={"campaignMonitoringViz"} />
                                </Box>
                                : ""}
                        {/* ------- Campaign Name Filter ----------- */}
                            {typeof userStateDate.campaign_list.campaign_name_obj !== "undefined"
                                ? <Box sx={{ width: 250, alignContent: "center" }}>
                                    <CampaignNameFilter usedFor={"clip-overview"} fieldName="name_campaign" vizId={"campaignMonitoringViz"} />
                                </Box>
                                : ""}
                        {/* ------------ day timestamp filter ------------ */}
                            <Box sx={{ width: 250, alignSelf: "flex-end" }}>
                                <DateRangeFilter
                                    fieldName={"Date"}
                                    minSelectableDate={startDate}
                                    maxSelectableDate={dbFormatDate(yesterday)}
                                    onValueChange={filterRangeValue}
                                    vizId={"campaignMonitoringViz"}
                                />
                            </Box>
                </Box>
               </StickyTopRowStackSpaceBetween> 
                <Box height={1} />
                <Grid container spacing={2}>
                    {typeof workSpaceId !== "undefined" 
                    ? <TableauDash
                        vizId="campaignMonitoringViz"
                        // vizUrl={"https://dub01.online.tableau.com/t/tableaucyreende/views/" + 3 + "_Total_Campaigns_v2/CampaignMonitoring"}
                        vizUrl={"https://dub01.online.tableau.com/t/tableaucyreende/views/" + workSpaceId + "_Total_Campaigns_v2/CampaignMonitoring"}
                        applyFilter={false}
                        filterConfig={{
                            // store: {
                            //     name: "Name Store",
                            //     values: storeListOptionsForTableauFilter("overview"),
                            // },
                        }}
                    />
                    :""}
                
                </Grid>
            </StyledMainBox>
            : <Box>
            {typeof userStateDate.loading !== "undefined" && userStateDate.loading === true ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                <StyledMainBox component="main">
                    <p>Please wait while we are loading results...</p>
                </StyledMainBox>
            </Box>
            ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <StyledMainBox component="main">
                    {typeof userStateDate.campaign_list === "undefined" ?
                        <p>No campaigns found...</p>
                    :""}
                    
                    { typeof userStateDate.clips_list !== "undefined" ?
                        <p>No clips found...</p>
                    :""}
                    </StyledMainBox>
                </Box>
            )}
        </Box>}
        </Box>
    )
}
export default ClipOverview;